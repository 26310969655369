<template>
    <div class="FeedbackCollection" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="100px"
            >
                <el-row style="margin-bottom: 20px">
                    <el-col :span="6">
                        <el-form-item label="需求名称">
                            <el-input placeholder="需求名称" v-model="form.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="需求分类">
                            <el-select
                                filterable
                                v-model="form.menuCode"
                                @change="handleChangeGroup"
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="反馈机构">
                            <el-select v-model="form.deptCode">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="反馈人">
                            <el-input placeholder="反馈人姓名/手机号" v-model="form.creatorOrMobile" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="6">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                    <el-col :span="6">
                        <el-button
                            type="primary"
                            size="small"
                            @click="handleQuery"
                            v-if="hasPrivilege('menu.user.feedBack.open')"
                            >查询</el-button
                        >
                        <el-button
                            type="primary"
                            size="small"
                            @click="handleExport"
                            v-if="hasPrivilege('menu.user.feedBack.export')"
                            >导出</el-button
                        >
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div class="typeLine">
                <span class="title">需求状态</span>
                <el-radio-group @change="handleQuery" v-model="form.status">
                    <el-radio-button label="0">全部</el-radio-button>
                    <el-radio-button label="1">待受理</el-radio-button>
                    <el-radio-button label="2">受理中</el-radio-button>
                    <el-radio-button label="3">已上线</el-radio-button>
                    <el-radio-button label="4">已关闭</el-radio-button>
                </el-radio-group>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="需求名称" prop="name" />
                <el-table-column label="需求分类" prop="menuDesc" />
                <el-table-column label="需求状态" prop="statusDesc" />
                <el-table-column label="反馈机构" width="300" prop="deptDesc" />
                <el-table-column label="反馈人" prop="creator" />
                <el-table-column label="创建时间" prop="createTime" />
                <el-table-column label="更新时间" prop="updateTime" />
                <el-table-column label="操作" min-width="100" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" @click="viewFeedback(scope.row)">查看</el-button>
                        <el-button
                            size="mini"
                            type="text"
                            v-if="hasPrivilege('biz.user.feedBack.accept') && scope.row.statusDesc === '待受理'"
                            @click="acceptanceFeedback(scope.row)"
                            >受理</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            v-if="hasPrivilege('biz.user.feedBack.online') && scope.row.statusDesc === '受理中'"
                            @click="onlineFeedback(scope.row)"
                            >上线</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            v-if="
                                (hasPrivilege('biz.user.feedBack.close') && scope.row.statusDesc === '待受理') ||
                                scope.row.statusDesc === '受理中'
                            "
                            @click="closeFeedback(scope.row)"
                            >关闭</el-button
                        >
                        <el-button
                            size="mini"
                            type="text"
                            v-if="hasPrivilege('biz.user.feedBack.reaccpet') && scope.row.statusDesc === '已关闭'"
                            @click="acceptanceFeedback(scope.row)"
                            >重新受理</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <!--        受理弹框-->
        <el-dialog title="受理" width="30%" :visible.sync="dialogList.acceptanceDialog">
            <el-form enctype="multipart/form-data" :model="acceptanceDialogForm" :rules="acceptanceDialogFormRules">
                <el-form-item label="需求负责人" label-width="100px" style="margin-bottom: 20px" prop="name">
                    <el-input
                        style="width: 100%"
                        placeholder="负责人姓名"
                        v-model="acceptanceDialogForm.name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注" label-width="100px" prop="remarks">
                    <el-input
                        type="textarea"
                        show-word-limit
                        maxlength="200"
                        v-model="acceptanceDialogForm.remarks"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogList.acceptanceDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureChange(2)">确 定</el-button>
            </div>
        </el-dialog>
        <!--        关闭弹唱-->
        <el-dialog title="关闭" width="35%" :visible.sync="dialogList.closeDialog">
            <el-form enctype="multipart/form-data" :rules="closeRules">
                <el-form-item label="关闭原因" label-width="80px" prop="closeReason">
                    <el-input
                        type="textarea"
                        show-word-limit
                        maxlength="200"
                        v-model="closeDialogForm.closeReason"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogList.closeDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureChange(4)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfEndDate from 'components/EfEndDate';
import EfStartDate from 'components/EfStartDate';
export default {
    name: 'FeedbackCollection',
    components: { EfEndDate, EfStartDate },
    data() {
        return {
            deptGroupCode: '',
            form: {
                status: '0',
                menuCode: '',
                deptCode: '',
                state: '',
                creatorOrMobile: '', //反馈人或手机号
                page: 1,
                limit: Util.Limit,
                startTime: '',
                endTime: '',
            },
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
                page: '/userFeedBack/pageExtend',
                changeStatus: '/userFeedBack/changeStatus',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                groups: [],
                deptCodes: [],
                cards: [],
            },
            loadingDeptGroupFlag: true,
            deptSelectComponentLoadingCompleteFlag: false,
            tableData: [],
            dialogList: {
                acceptanceDialog: false,
                closeDialog: false,
            },
            acceptanceDialogForm: {
                name: '',
                remarks: '',
            },
            acceptanceDialogFormRules: {
                name: [{ required: true, min: 1, max: 30, message: '负责人姓名不可为空。', trigger: 'blur' }],
            },
            closeDialogForm: {
                closeReason: '',
            },
            closeRules: {
                closeReason: [{ required: true, min: 1, max: 30, message: '关闭原因不可为空。', trigger: 'blur' }],
            },
            nowChoseItem: {},
        };
    },
    mounted() {
        this.handleChangeGroup();
        UrlUtils.QueryRemote(this, '/authedMenus', (res) => {
            this.meta.groups = res;
            this.loadingDeptGroupFlag = false;
        });
    },
    created() {
        this.handleQuery();
    },
    methods: {
        //查看反馈单
        viewFeedback(item) {
            Util.nameJump(this, 'menu.user.feedBack.viewFeedback', ['系统管理', '角色管理', '编辑角色'], {
                item: item,
            });
        },
        //受理反馈单
        acceptanceFeedback(item) {
            this.acceptanceDialogForm = {
                name: '',
                remarks: '',
            };
            this.dialogList.acceptanceDialog = true;
            this.nowChoseItem = item;
        },
        closeFeedback(item) {
            this.closeDialogForm = {
                closeReason: '',
            };
            this.dialogList.closeDialog = true;
            this.nowChoseItem = item;
        },
        onlineFeedback(item) {
            this.nowChoseItem = item;
            this.$alert('确认后将上线', '上线', {
                confirmButtonText: '确定',
            })
                .then(({ value }) => {
                    this.sureChange(3);
                })
                .catch(() => {
                    this.$message.info('已取消');
                });
        },
        isNull(str) {
            if (str === '') return true;
            var regu = '^[ ]+$';
            var re = new RegExp(regu);
            return re.test(str);
        },
        //受理确认
        sureChange(afterStatus) {
            if (afterStatus == 4) {
                if (this.isNull(this.closeDialogForm.closeReason)) {
                    this.$message.error('关闭原因不可为空');
                    return;
                }
            }
            const params = {
                code: this.nowChoseItem.code,
                afterStatus: afterStatus,
                managerName: this.acceptanceDialogForm.name,
                remark: this.acceptanceDialogForm.remarks,
                closeReason: this.closeDialogForm.closeReason,
            };
            const _this = this;
            _this.$http.put(this.url.changeStatus, params).then((rst) => {
                this.$message.success(rst.data.message);
                this.dialogList.acceptanceDialog = false;
                this.dialogList.closeDialog = false;
                this.handleQuery();
            });
        },
        //新增意见反馈单
        gotoFeedback() {
            this.$router.push({ name: 'menu.user.feedBack.addFeedback' });
            // Util.nameJump(this, 'menu.user.feedBack.addFeedback');
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleChangeGroup() {
            const _this = this;
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + _this.deptGroupCode, (rst) => {
                _this.meta.deptCodes = rst;
            });
        },
        handleExport() {
            UrlUtils.Export(this, '意见反馈', '/userFeedBack/exportExtend', this.form);
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
    },
};
</script>

<style scoped>
.FeedbackCollection .el-form-item {
    width: 100%;
    margin-bottom: 0;
}
.typeLine {
    padding-left: 30px;
}
.title {
    font-size: 14px;
    color: #606266;
    margin-right: 10px;
}
</style>
